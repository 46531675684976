<template>
  <v-container
    class="pa-0 container--fluid"
  >
    <v-card
      class="ma-0 user_manage-accounts"
    >
      <div class="table-filters">
        <div class="row">
          <div class="col-lg col-md-4">
            <v-subheader class="input-label">
              User ID
            </v-subheader>
            <v-text-field
              v-model="filterId"
              type="number"
              single-line
              outlined
              dense
              hide-details
            />
          </div>
          <div class="col-lg col-md-4">
            <v-subheader class="input-label">
              Name
            </v-subheader>
            <v-text-field
              v-model="filterName"
              single-line
              outlined
              dense
              hide-details
            />
          </div>
          <div class="col-lg col-md-4">
            <v-subheader class="input-label">
              E-Mail
            </v-subheader>
            <v-text-field
              v-model="filterEmail"
              type="email"
              single-line
              outlined
              dense
              hide-details
            />
          </div>
          <div class="col-lg col-md-4">
            <v-subheader class="input-label">
              Phone
            </v-subheader>
            <v-text-field
              v-model="filterPhone"
              single-line
              outlined
              dense
              hide-details
            />
          </div>
          <div class="col-lg col-md-4">
            <v-subheader class="input-label">
              User Status
            </v-subheader>
            <v-select
              v-model="filterStatus"
              :items="[
                { name: 'All', value: '' },
                { name: 'Unconfirmed', value: 'unconfirmed' },
                { name: 'Active', value: 'active' },
                { name: 'Idle', value: 'idle'},
                { name: 'Blocked', value: 'blocked' },
                { name: 'Disabled', value: 'disabled' }
              ]"
              item-text="name"
              item-value="value"
              outlined
              dense
              hide-details
            />
          </div>
          <div class="col-lg col-md-4">
            <v-subheader class="input-label">
              User Role
            </v-subheader>
            <v-select
              v-model="filterRole"
              :items="[
                { name: 'All', value: '' },
                { name: 'User', value: 'user' },
                { name: 'Administrator', value: 'administrator'},
                { name: 'SuperAdmin', value: 'superadmin' }
              ]"
              item-text="name"
              item-value="value"
              outlined
              dense
              hide-details
            />
          </div>
          <div class="col-lg col-md-4">
            <v-subheader class="input-label">
              Idle Count
            </v-subheader>
            <v-select
              :items="[
                { name: '-', value: 'filter-idle' },
                { name: 'Sort by Idle Count', value: 'idleCount' },
              ]"
              item-text="name"
              item-value="value"
              outlined
              dense
              hide-details
              @change="sortIdle($event)"
            />
          </div>
        </div>
        <div class="table-filters__controls">
          <v-btn
            color="teal lighten-2"
            medium
          >
            Apply
          </v-btn>

          <v-btn
            color="red"
            medium
            @click="clearFilters"
          >
            Reset
          </v-btn>
        </div>
      </div>
      <div
        class="table-wrapper px-6 py-3"
        @click="rewriteWidths"
      >
        <v-data-table
          v-if="tableUsers"
          ref="usertable"
          v-model="selected"
          v-columns-resizable
          :page.sync="currentPage"
          :headers="userHeaders"
          :items="tableUsers"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          item-key="id"
          :footer-props="{
            showFirstLastPage: true,
            itemsPerPageOptions: [10, 20, 50, 100]
          }"
          :fixed-header="true"
          class="fixed-columns-table"
          @page-count="pageCount = $event"
        >
          <template v-slot:no-data>
            No data
          </template>
          <template v-slot:item.id="{ item }">
            <div v-if="item.userInfo.length">
              <v-tooltip
                right
                :offset-overflow="true"
                close-delay="300"
              >
                <template v-slot:activator="{ on, attrs }">
                  <router-link
                    v-slot="{ href }"
                    :to="{ name: 'Edit User', params: { id: item.id } }"
                    class="link__hidden"
                    target="_blank"
                  >
                    <a
                      :href="href"
                      target="_blank"
                    >
                      <span
                        class="popup-toggler text-bold"
                        v-bind="attrs"
                        v-on="on"
                      >
                        {{ item.id }}
                      </span>
                    </a>
                  </router-link>
                </template>
                <div class="tooltip-content note-tooltip">
                  <div
                    v-for="(info, i) in item.userInfo"
                    :key="i"
                    class="user-info-item"
                  >
                    {{ `${$options.filters.dateFormatOnly(info.reminderDueDate)} / ${info.createdBy} / ${info.info}` }}
                    <v-divider
                      dark
                      class="my-2"
                    />
                  </div>
                </div>
              </v-tooltip>
            </div>
            <div v-else>
              <router-link
                v-slot="{ href }"
                :to="{ name: 'Edit User', params: { id: item.id } }"
                class="link__hidden"
                target="_blank"
              >
                <a
                  :href="href"
                  target="_blank"
                >
                  <span>
                    {{ item.id }}
                  </span>
                </a>
              </router-link>
            </div>
          </template>
          <template v-slot:item.name="{ item }">
            <router-link
              v-slot="{ href }"
              :to="{ name: 'Edit User', params: { id: item.id } }"
              class="link__hidden"
            >
              <a
                :href="href"
                target="_blank"
              >
                <span>
                  {{ item.name }}
                </span>
              </a>
            </router-link>
          </template>
          <template v-slot:item.email="{ item }">
            <span class="break-content">
              {{ item.email }}
            </span>
          </template>
          <template v-slot:item.phone="{ item }">
            <span class="break-content">
              {{ item.phone }}
            </span>
          </template>
          <template v-slot:item.confirmed="{ item }">
            <v-icon
              v-if="item.confirmed"
              color="green darken-2"
            >
              mdi-check
            </v-icon>
            <v-icon
              v-else
              color="red"
            >
              mdi-close
            </v-icon>
          </template>
          <template v-slot:item.createdAt="{ item }">
            {{ item.createdAt | dateFormat }}
          </template>
          <template v-slot:item.lastLogin="{ item }">
            {{ item.lastLogin | dateFormat }}
          </template>
          <template v-slot:item.status="{ item }">
            {{ item.status }}
          </template>
          <template v-slot:item.productsCount="{ item }">
            <router-link
              v-slot="{ href }"
              :to="{ name: 'Edit User', params: { id: item.id }, query: { activeTab: 'Objects' } }"
              class="link__hidden"
            >
              <a
                :href="href"
                target="_blank"
              >
                <span>
                  {{ item.productsCount }}
                </span>
              </a>
            </router-link>
          </template>
          <template v-slot:item.leasingRequests="{ item }">
            {{ item.leasingRequests }}
          </template>
          <template v-slot:item.rentingRequests="{ item }">
            {{ item.rentingRequests }}
          </template>
          <template v-slot:item.action="{ item }">
            <div class="table-actions">
              <v-select
                :ref="`select-${item.id}`"
                append-icon=""
                :items="['Add User Notes', 'Conversations', 'Send SMS', { text: 'Disable User', value: 'Disable User', disabled: item.status.toLowerCase() === 'deleted' }, { text: 'Restore User', value: 'Restore User', disabled: item.status.toLowerCase() !== 'deleted' }]"
                filled
                color="teal"
                light
                dense
                single-line
                class="action-select"
                @change="userAction($event, item)"
              >
                <template v-slot:label>
                  <v-icon color="white">
                    mdi-cog-outline
                  </v-icon>
                </template>
              </v-select>
              <v-btn
                color="teal lighten-2"
                elevation="0"
                class="action-edit"
                @click="userAction('edit user', item)"
              >
                <v-icon left>
                  mdi-pencil
                </v-icon>
              </v-btn>
              <v-btn
                color="red"
                elevation="0"
                class="action-btn"
                @click="deleteAction(item)"
              >
                <v-icon left>
                  mdi-delete
                </v-icon>
              </v-btn>
            </div>
          </template>
          <template v-slot:footer>
            <v-pagination
              v-model="currentPage"
              class="table-pagination"
              :length="pageCount"
              total-visible="7"
            />
          </template>
        </v-data-table>
      </div>
    </v-card>
  </v-container>
</template>

<script>
  import { mapState, mapActions } from 'vuex'
  import axios from '@/plugins/api'

  export default {
    data () {
      return {
        currentPage: 1,
        pageCount: 0,
        tableUsers: null,
        sortBy: '',
        sortDesc: false,
        selected: [],
        filterId: '',
        filterName: '',
        filterEmail: '',
        filterPhone: '',
        filterStatus: '',
        filterRole: '',
        filterIdleCount: null,
        appliedFilters: {},
        usersTotal: 0,
        userHeaders: [
          { text: 'User ID', value: 'id', align: 'center', width: '100px' },
          { text: 'Name', value: 'name', width: '200px' },
          { text: 'E-mail', value: 'email', align: 'center', width: '200px' },
          { text: 'Phone', value: 'phone', align: 'center', width: '150px' },
          { text: 'Confirmed', value: 'confirmed', align: 'center', width: '115px' },
          { text: 'Created', value: 'createdAt', align: 'center', width: '150px' },
          { text: 'Last Login', value: 'lastLogin', align: 'center', width: '150px' },
          { text: 'User Status', value: 'status', align: 'center', width: '130px' },
          { text: 'Objects', value: 'productsCount', align: 'center', width: '95px' },
          { text: 'Leasing Requests', value: 'leased', align: 'center', width: '150px' },
          { text: 'Renting Requests', value: 'rented', align: 'center', width: '150px' },
          { text: 'Rating Count/Avg', value: 'ratingCount', align: 'center', width: '115px' },
          { text: 'Idle Count', value: 'idleCount', align: 'center', width: '115px' },
          { text: 'Action', value: 'action', align: 'center', width: '110px' },
        ],
        localWidths: [],
      }
    },

    computed: {
      ...mapState({
        users: state => state.users.users,
      }),
    },

    watch: {
      filterId: {
        handler () { this.filterUsers('filterId', 'id', 'text') },
      },
      filterName: {
        handler () { this.filterUsers('filterName', 'name', 'text') },
      },
      filterEmail: {
        handler () { this.filterUsers('filterEmail', 'email', 'text') },
      },
      filterPhone: {
        handler () { this.filterUsers('filterPhone', 'phone', 'text') },
      },
      filterStatus: {
        handler () { this.filterUsers('filterStatus', 'status', 'select') },
      },
      filterRole: {
        handler () { this.filterUsers('filterRole', 'role', 'select') },
      },
      users: {
        immediate: true,
        handler () {
          this.tableUsers = this.users.map(el => {
            el.ratingCount = el.rating.ratingAvg
            return el
          })
        },
      },
    },

    created () {
      if (localStorage.getItem('userColumnWidths')) {
        JSON.parse(localStorage.getItem('userColumnWidths')).forEach(el => {
          this.userHeaders.find(item => item.value === el.name).width = el.width
        })
      }
      this.fetchUsers()
    },

    methods: {
      ...mapActions({
        fetchUsers: 'fetchUsers',
        disableUser: 'disableUser',
        restoreUser: 'restoreUser',
      }),
      userAction (val, user) {
        if (val.toLowerCase() === 'edit user') {
          this.$router.push(`user-accounts/${user.id}`)
        } else if (val.toLowerCase() === 'disable user') {
          this.disableUser(user.id)
        } else if (val.toLowerCase() === 'restore user') {
          this.restoreUser(user.id)
        } else if (val.toLowerCase() === 'add user notes') {
          this.$router.push(`user-accounts/${user.id}?activeTab=Notes`)
        } else if (val.toLowerCase() === 'conversations') {
          this.$router.push(`user-accounts/${user.id}?activeTab=Conversations`)
        } else if (val.toLowerCase() === 'send sms') {
          this.$router.push(`user-accounts/${user.id}?activeTab=WebSMS`)
        }
        this.$refs[`select-${user.id}`].internalValue = []
        this.$refs[`select-${user.id}`].blur()
      },
      deleteAction (user) {
        if (!confirm('Are you sure you want to delete this user?')) return

        axios.post('/admin/users', {
          id: user.id,
        })
          .then(() => {
            this.fetchUsers()
          })
      },
      clearFilters () {
        this.filterId = ''
        this.filterName = ''
        this.filterEmail = ''
        this.filterPhone = ''
        this.filterStatus = ''
        this.filterRole = ''
        this.filterIdleCount = null
        this.tableUsers = this.users
      },
      filterUsers (filterName, tableField, type) {
        if (this[filterName]) {
          if (this.appliedFilters[filterName]) {
            this.appliedFilters[filterName].applied = true
          } else {
            this.appliedFilters[filterName] = {
              applied: true,
              tableField: tableField,
              type: type,
            }
          }
        } else {
          this.appliedFilters[filterName].applied = false
        }

        const trigger = Object.keys(this.appliedFilters).filter(el => this.appliedFilters[el].applied)

        if (trigger.length) {
          let filteredUsers = [...this.users]

          if (this.filterIdleCount && this.filterIdleCount === 'filter-idle') {
            filteredUsers = filteredUsers.filter(el => el.status.toLowerCase() === 'idle')
          }

          Object.keys(this.appliedFilters).forEach(obj => {
            if (obj !== 'filterIdleCount' && this.appliedFilters[obj].applied && this[obj]) {
              filteredUsers = this.filterData(filteredUsers, obj, this.appliedFilters[obj].tableField, this.appliedFilters[obj].type)
            }
          })

          if (this.filterIdleCount && this.filterIdleCount === 'sort-idle') {
            filteredUsers = filteredUsers.sort((a, b) => a.idleCount - b.idleCount)
          }

          this.tableUsers = filteredUsers
        } else {
          this.tableUsers = this.users
        }
      },

      filterData (data, filterName, tableField, type) {
        let filteredData = data
        switch (type) {
          case 'text':
            filteredData = filteredData.filter(el => el[tableField].toString().toLowerCase().includes(this[filterName].toString().toLowerCase()))
            break
          case 'select':
            filteredData = filteredData.filter(el => el[tableField].toString().toLowerCase() === this[filterName].toString().toLowerCase())
            break
          default:
            break
        }
        return filteredData
      },

      rewriteWidths () {
        const headers = document.querySelectorAll('.v-data-table__wrapper th')
        const widths = []
        headers.forEach(el => {
          if (el.getAttribute('style').includes('width:')) {
            const name = el.querySelector('span').innerText
            const headerItem = this.userHeaders.find(item => item.text === name)
            const width = `${el.offsetWidth}px`
            widths.push({ name: headerItem.value, width })
          }
        })
        if (widths.length > 0) {
          localStorage.setItem('userColumnWidths', JSON.stringify(widths))
        }
      },
      sortIdle (val) {
        if (val === 'idleCount') {
          this.sortBy = 'idleCount'
        }
      },
    },
  }
</script>

<style lang="scss">
.v-tooltip__content {
  pointer-events: auto !important;
  padding: 15px;

  &:hover {
    display: block !important;
    opacity: 1 !important;
  }
}

.fixed-columns-table .v-data-table__wrapper table th:nth-child(2),
.fixed-columns-table .v-data-table__wrapper table td:nth-child(2) {
  left: calc(100px) !important;
}
</style>
